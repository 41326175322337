.footer {

  background-color: #ffffff;
  height: auto;
  width: 100%;
  text-align: left;
  padding-bottom: 80px;
  padding-top: 0px;
}

.footer-hero {
  grid-template-columns: repeat(4, auto);
  display: grid;
  text-align: left;
  align-items:baseline;
  justify-items: center;
}

.footer-hero-column {
  grid-template-columns: repeat(0, auto);
  display: grid;
  align-items:baseline;
  text-align: left;
}

.footer p {
  font-size: 16px;
  line-height: 1.5em;
  margin-bottom: -0px;
}

.footer a {
  text-decoration: none;
}

.footer a:hover p {
  color: #2A313B;
}

.footer-hero-column .footer-copyright {
  font-size: 16px;
}

.footer-social {
  grid-template-columns: repeat(3, auto);
  display: grid;
  align-items: center;
  text-align: left;

}

.footer-social-instagram {
  background-image: url("../images/icons/instagram-48.png");
  width: 24px;
  height: 24px;
  background-size: 100%;
  background-size:cover;
  background-repeat: no-repeat;
  margin-right: 16px;
  background-position: center;
}

.footer-social-twitter {
  background-image: url("../images/icons/icons8-twitter-50.png");
  width: 28px;
  height: 28px;
  background-size: 100%;
  background-size:cover;
  background-repeat: no-repeat;
  margin-right: 16px;
}

.footer-social-angelist {
  background-image: url("../images/icons/AngelList.png");
  width: 28px;
  height: 28px;
  background-size: 100%;
  background-size:contain;
  background-repeat: no-repeat;
}

@media (max-width: 800px) {

.footer {
  padding-top: 20px;
}

.footer-hero {
grid-template-columns: auto;
display: box;
text-align: center;
align-items:baseline;
justify-items: center;
}

.footer-logo {
display: none;
}

.footer-hero-column {
text-align: center;
}

}
