
.form-wrapper {
  grid-template-columns: repeat(0, auto);
  display: grid;
  align-items: baseline;
  justify-items: center;
  margin: 0;
  margin-top: -100px;
}

.form-wrapper p {
  font-size: 14px;
  font-weight: 400;
  font-family: 'Inter', 'Roboto', sans-serif;
  color: #848484;
  margin-top: -0px;
}

.msg-alert p {
  font-size: 14px;
  font-weight: 600;
  font-family: 'Inter', 'Roboto', sans-serif;
  text-transform:uppercase;
  color: #848484;
}

.form-alert {
  font-size: 14px;
  font-weight: 600;
  font-family: 'Roboto', sans-serif;
  text-transform:uppercase;
  color: #848484;
}
input {
  padding-left: 2em;
  padding-right: 2em;
  padding-top: 0em;
  padding-bottom: 0em;
  background-color: #ffffff;
  border-radius: 160px;
  width: 320px;
  border: none;
  box-shadow: 0px 0px 16px rgba(0,0,0, 0.05);
  margin-right: 32px;
  max-height: 52px;
  min-height: 52px;
  font-size: 18px;
  font-weight: 600;
  font-family: 'Inter', 'Roboto', sans-serif;
  margin-bottom: 16px;
}

input:focus  {
  border: none;
  outline: none;
}

input[type="text"] {
  outline-style: none;
}

button {
  padding-left: 2em;
  padding-right: 2em;
  padding-top: 0em;
  padding-bottom: 0em;
  background-color: #18AFD1;
  border-radius: 72px;
  width: 144px;
  border: none;
  box-shadow: 0px 0px 16px rgba(0,0,0, 0.05);
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Roboto', sans-serif;
  max-height: 52px;
  min-height: 52px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.5em;
  font-family: 'Inter', 'Roboto', sans-serif;
  height: 56px;
  cursor: pointer;
  outline-style: none;


}


button:enabled {
  outline-style: none;

}
button:hover {
  cursor: pointer;
}

@media (max-width: 800px) {

  .form-wrapper {
    grid-template-columns: repeat(0, auto);
    display: grid;
    align-items: center;
    justify-items: center;
    margin: auto;
    max-width: 90%;
    min-width: 50px;
    margin-top: 4px;
  }

  .form-wrapper p {
    font-size: 14px;
    font-weight: 400;
    font-family: 'Inter', 'Roboto', sans-serif;
    color: #848484;
    margin-top: 16px;
  }

  input {
    width: 80%;
    border: none;
    margin-right: 0px;
    margin-bottom: 16px;
    margin-top: 0px;

  }

  button {
    padding-left: 2em;
    padding-right: 2em;
    background-color: #18AFD1;
    width: auto;
    border: none;
    box-shadow: 0px 0px 8px rgba(0,0,0, 0.15);
    color: #ffffff;
  }

}

@media (max-width: 428px) {

.form-wrapper {
 display: block;
}

}
