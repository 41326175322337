@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@500;600;700&family=Roboto:wght@400;500;700&display=swap');


@font-face {
  font-family: 'F37Bobby';
  font-style: bold;
  font-weight: 800;
  src: url('../fonts/Bobby/F37-Bobby.woff2'); /* IE9 Compat Modes */
  src: local('F37-Bobby'), local('F37-Bobby'),
       url('../fonts/Bobby/F37-Bobby.woff2') format('woff2'), /* IE6-IE8 */
       url('../fonts/Bobby/F37-Bobby.woff2') format('woff2'), /* Super Modern Browsers */
}

@font-face {
  font-family: 'F37Bobby';
  font-style: light;
  font-weight: 400;
  src: url('../fonts/Bobby/F37Bobby-Light.ttf'); /* IE9 Compat Modes */
  src: local('F37Bobby'), local('F37Bobby'),
       url('../fonts/Bobby/F37Bobby-Light.ttf') format('truetype'), /* IE6-IE8 */
       url('../fonts/Bobby/F37Bobby-Light.otf') format('opentype'), /* Super Modern Browsers */
}


@font-face {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/Satoshi/Satoshi-Regular.woff2'); /* IE9 Compat Modes */
  src: local('Satoshi'), local('Satoshi'),
       url('../fonts/Satoshi/Satoshi-Regular.woff2') format('woff2'), /* IE6-IE8 */
       url('../fonts/Satoshi/Satoshi-Regular.woff2') format('woff2'), /* Super Modern Browsers */
}

@font-face {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/Satoshi/Satoshi-Medium.woff2'); /* IE9 Compat Modes */
  src: local('Satoshi'), local('Satoshi'),
       url('../fonts/Satoshi/Satoshi-Medium.woff2') format('woff2'), /* IE6-IE8 */
       url('../fonts/Satoshi/Satoshi-Medium.woff2') format('woff2'), /* Super Modern Browsers */
}

@font-face {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/Satoshi/Satoshi-Bold.woff2'); /* IE9 Compat Modes */
  src: local('Satoshi'), local('Satoshi'),
       url('../fonts/Satoshi/Satoshi-Bold.woff2') format('woff2'), /* IE6-IE8 */
       url('../fonts/Satoshi/Satoshi-Bold.woff2') format('woff2'), /* Super Modern Browsers */
}

.App-logo {
  height: auto;
}

.hidetitle {
  display: none;}

body {
  background-color: #ffffff;
  min-width: 100%;
  max-width: 100%;
}

Section {
  min-width: 100%;
  max-width: 100%;
  background-color: #ffffff;
  min-height: auto;
}

.Content-Wrapper {
  min-width: 100%;
  max-width: 100%;
}

.App-link {
  color: #17BADF;
}

h1, h2, h3, h4, h5, h6 {
  color: #1e1e1d;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'F37Bobby', 'Playfair Display', sans-serif;
  line-height: 1.2em;
  font-weight: 800;
  font-style: bold;
}

p {
  color: #1e1e1e;
  font-size: 18px;
  line-height: 2em;
  font-family: 'Satoshi', 'Roboto', sans-serif;
  font-weight: 400;
  font-style: normal;
}

h1 {
  font-size: 58px;
}

h2 {
  font-size: 48px;
}

h3 {
  font-size: 38px;
}


h4 {
  color: #1e1e1e;
  font-size: 28px;
  line-height: 1.5em;
  font-weight: 400;
  font-style: light;
  font-family: 'F37Bobby', 'Playfair Display', sans-serif;
}

h5 {
  color: #1e1e1e;
  font-size: 20px;
  line-height: 1.5em;
  font-weight: 400;
  font-family: 'F37Bobby', 'Playfair Display', sans-serif;
}


h6 {
  color: #1e1e1e;
  font-size: 18px;
  line-height: 1.5em;
  font-weight: 600;
  font-family: 'Satoshi', 'Roboto', sans-serif;
}


.Content-Wrapper a {
  color: #848484;
  font-size: 14px;
  line-height: 2em;
  font-family: 'Satoshi', 'Roboto', sans-serif;
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
}

@media (max-width: 800px) {

  .Content-Wrapper {
    min-width: 100%;
    max-width: 800px;
  }


  Section {
    min-width: 100%;
    max-width: 100%;
    padding-top: 80px;
  }

  h1 {
    font-size: 48px;
  }

  h2 {
    font-size: 38px;
  }

  h3 {
    font-size: 28px;
  }

  p {
    font-size: 18px;
  }

}


@media (max-width: 414px) {

  .Content-Wrapper {
    min-width: 100%;
    max-width: 414px;
  }

  Section {
    min-width: 100%;
    max-width: 414px;
  }

  .footer  {
    margin-top: 64px;
  }
}
