.Navbar {
  top: 0;
  position: fixed;
  max-width: 100%;
  min-width: 100%;
  height: 80px;
  z-index: 100;
  background-color: clear;
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-columns: repeat(1, auto);
  position: absolute;
}

.NavbarScrolled {
  /* box-shadow: 0 0px 24px rgba(0,0,0, 0.15); */
  position: sticky;
  position: fixed;
  margin-top: 10px;
}


.Navbar h1 {
  color: #00A2C7;
  font-size: 28px;
}


.navBarItems {
  background-color: linear-gradient(137deg, rgba(255, 255, 255, 0.70) 24.15%, rgba(255, 255, 255, 0.62) 125.95%);
  text-align: center;
  grid-template-columns: repeat(7, auto);
  align-items: center;
  justify-items: center;
  width:1200px;
  display: grid;
  border-radius: 40px;
}

.ToolbarScrolled {
  background-color: linear-gradient(137deg, rgba(255, 255, 255, 0.70) 24.15%, rgba(255, 255, 255, 0.62) 125.95%);
  -webkit-backdrop-filter: blur(35px);
  backdrop-filter: blur(35px);
  border-radius: 16px;
}

.ToolbarScrolled p {
  color: #fff;
}

.navbar-logo {
  width: 70px;
  height: 70px;
  background-size: 100% 100%;
  background-size: contain;
  padding: 0;
  background-repeat: no-repeat;
  cursor: pointer;
}

.navBarItems p {
  cursor: pointer;
  font-size: 16px;
  color: #2C2B34;
  font-weight: 600;
  font-style: normal;
}

.navbar-SignUp {
  width: 184px;
  height: 52px;
  background-color: #18AFD1;
  border-radius: 26px;
  outline: none;
  cursor: pointer;
  box-shadow: 0px 0px 8px rgba(0,0,0, 0.15);
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1.0);
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-SignUp:hover {
  box-shadow: 0px 0px 16px rgba(0,0,0, 0.15);
  transform: translateY(-4px);
  /* background-color: #0828BA; */
}

/* 01BAC7
*/

.navbar-SignUp p {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  margin-top: -0;
}

/* .navbar-SignUp:hover p {
  color: #ffffff;
} */

.buttonText {
  align-items: center;
  justify-items: center;
  text-align: center;
  vertical-align: center;
  display: inline-block;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding: 0;
  margin-top: 12px;
  font-size: 17px;
}

@media (max-width: 1440px) {

.navBarItems {
  background-color: linear-gradient(137deg, rgba(255, 255, 255, 0.70) 24.15%, rgba(255, 255, 255, 0.62) 125.95%);
  text-align: center;
  grid-template-columns: repeat(7, auto);
  align-items: center;
  justify-items: center;
  width: 90%;
  display: grid;
  border-radius: 16px;
}

.ToolbarScrolled {
  background-color: linear-gradient(137deg, rgba(255, 255, 255, 0.70) 24.15%, rgba(255, 255, 255, 0.62) 125.95%);
  -webkit-backdrop-filter: blur(35px);
  backdrop-filter: blur(35px);
  border-radius: 16px;
}

}


@media (max-width: 800px) {

  .Navbar {
    top: 0;
    position: sticky;
    position: fixed;
    max-width: 100%;
    min-width: 100%;
    height: 70px;
    z-index: 100;
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-columns: repeat(1, auto);
  }
  
  /* .NavbarScrolled {
    box-shadow: 0 0px 24px rgba(0,0,0, 0.15);
    background-color: white;
    position: sticky;
    position: fixed;
    height: 70px;
  } */

.navbar-SignUp {
  /* display: none; */
  /* width: 40px; */
   height: 44px;
   width: 140px;
   margin-right: -20px;
}


.navBarItems .navbar-SignUp p {
  display: block;
  font-size: 14px;
}


.Navbar  p {
  display: none;
}

.navbar-logo {
  width: 70px;
  height: 70px;
  background-size: 100% 100%;
  background-size: contain;
  padding: 0;
  background-repeat: no-repeat;
  cursor: pointer;
  margin-left: 0px;
  margin-top: 0px;
}

.Navbar h1 {
  color: #00A2C7;
  font-size: 22px;
}


.navBarItems {
  background-color: linear-gradient(137deg, rgba(255, 255, 255, 0.70) 24.15%, rgba(255, 255, 255, 0.62) 125.95%);
  text-align: center;
  grid-template-columns: repeat(7, auto);
  align-items: center;
  justify-items: center;
  width: 95%;
  display: grid;
  border-radius: 16px;
}

.ToolbarScrolled {
  background-color: linear-gradient(137deg, rgba(255, 255, 255, 0.70) 24.15%, rgba(255, 255, 255, 0.62) 125.95%);
  -webkit-backdrop-filter: blur(35px);
  backdrop-filter: blur(35px);
  border-radius: 16px;
}


}

@media (max-width: 414px) {


.Navbar h1 {
  font-size: 20px;
}

.navBarItems {
  background-color: linear-gradient(137deg, rgba(255, 255, 255, 0.70) 24.15%, rgba(255, 255, 255, 0.62) 125.95%);
  text-align: center;
  grid-template-columns: repeat(7, auto);
  align-items: center;
  justify-items: center;
  width: 90%;
  display: grid;
  border-radius: 16px;
}

.ToolbarScrolled {
  background-color: linear-gradient(137deg, rgba(255, 255, 255, 0.70) 24.15%, rgba(255, 255, 255, 0.62) 125.95%);
  -webkit-backdrop-filter: blur(35px);
  backdrop-filter: blur(35px);
  border-radius: 16px;
}

}

@media (max-width: 375px) {



}