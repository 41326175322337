.section-wrapper {
  background: #fff;
  /* background: linear-gradient(180deg, rgba(249,249,249,1) 0%, rgba(255,255,255,1) 100%); */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;
  text-align: center;
  min-width: 100%;
  max-width: 100%;
  margin-top: 0px;
  margin-bottom: 0px;
  grid-template-columns: repeat(1, auto);
  /* border: red;
  border-radius: 3px;
  border-width: 3px;
  border-style: solid; */
}

.section-wrapper h2 {
  font-size: 48px;
  line-height: 54px;
  font-weight: 800;
  font-style: normal;
}

.review-card {
  border-radius: 34px;
  min-width: 1200px;
  max-width: 1200px;
  background-color: #F8F8F8;
  border-radius: 34;
  margin-top: 120px;
  margin-bottom: 0px;
  text-align: left;
  /* border: green;
  border-radius: 3px;
  border-width: 3px;
  border-style: solid; */
}

.review-card-wrapper {
  grid-template-columns: repeat(2, auto);
  display: grid;
  align-items: center;
  justify-items: center;
  padding: 40px;
}

.review-card h2 {
  font-size: 24px;
  color: #1d1d1d;
  width: 680px;
  line-height: 1.8em;
  font-family: 'Satoshi', 'Roboto', sans-serif;
  font-weight: 400;
  font-style: normal;
}

.ravicard {
  grid-template-columns: repeat(1, auto);
  display: grid;
  align-items: center;
  justify-items: center;
  height: 200px;
}

.raviimage {
  background-image: url("../images/durhamlivingblack.png");
  background-size: 100% 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 120px;
  height: 120px;
  border-radius: 80px;
  border-width: 4px;
  border-color: #F2C94C;
  border-style: solid;
}

.jessimage {
  background-image: url("../images/jess.jpg");
  background-size: 100% 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 120px;
  height: 120px;
  border-radius: 80px;
  border-width: 4px;
  border-color: #F2C94C;
  border-style: solid;
}

.ravicard h6 {
  font-size: 24px;
  margin-top:20px;
}

.ravicard p {
  margin-top:-60px;
}

/* RENTERS PASS SECTION */

.renterspass-hero {
  width: 1200px;
  grid-template-columns: repeat(2, auto);
  display: grid;
  align-items: center;
  justify-items: left;
  margin-top: 120px;
  text-align: left;
  /* border: blue;
  border-width: 1px;
  border-style: solid; */
  /* grid-gap: 60px; */
}

.renterspass-heading {
  text-align: left;
  color: #1d1d1d;
  width: 620px;
  padding: 0px;
  grid-template-columns: repeat(1, auto);
  display: grid;
  align-items: center;
  justify-items: left;
  text-align: left;
}

.renterspass-heading p {
  text-align: left;
}
.renterspass-hero-img {
  background-image: url("../images/RentersPassImage.png");
  width: 520px;
  height: 580px;
  background-size: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  /* border: yellow;
  border-width: 1px;
  border-style: solid; */
}

.marketplace-img {
  background-image: url("../images/marketplace-card.png");
  width: 520px;
  height: 580px;
  background-size: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  /* border: yellow;
  border-width: 1px;
  border-style: solid; */
}

.comingsoon {
  background-color: F8F8F8;
  border-radius: 16px;
  height: 32px;
  font-size: 16px;
  width: 186px;
  /* padding-left: 16px; */
  color: #fff;
}

.feature-list {
  grid-template-columns: repeat(1, auto);
  display: grid;
  align-items: left;
  justify-items: left;
  text-align: left;
  grid-gap: 0px;
}

.feature-list-item {
  grid-template-columns: repeat(2, auto);
  display:grid;
  align-items: center;
  justify-items: left;
  grid-gap: 8px;
  text-align: left;
  align-self: auto;
}

.feature-list-item p {
  text-align: left;
  width: 100%;
  align-self:flex-start;
}
/* PROPERTY SECTION */ 

.property-feature-img {
  background-image: url("../images/PropertyImage.png");
  width: 520px;
  height: 580px;
  background-size: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: block;
  margin-right: 160px;
  /* border: yellow;
  border-width: 1px;
  border-style: solid; */
}

.property-feature-img-mobile {
display: none;
}
.property-heading {
  text-align: left;
  width: 580px;
}

/*APP PREVIEW DOWNLOAD */
.apppreview-hero-img {
  background-image: url("../images/aisonapppreview.png");
  width: 520px;
  height: 580px;
  background-size: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 100px;
  margin-top: 0px;
  border-radius: 34px;
}

@media (max-width: 1440px) {
  .property-feature-img {
    margin-right: 100px;
  }

}

@media (max-width: 1280px) {

  .section-wrapper {
    margin-bottom: 0px;
    grid-template-columns: repeat(1, auto);
    max-width: 1280px;
    min-width: 960px;

  }

  .renterspass-hero {
    width: 90%;
    grid-template-columns: repeat(2, auto);
  }

  .renterspass-hero p {
    font-size: 18px;
  }


  .renterspass-heading {
    width: 100%;
  }


  .property-feature-img {
    width: 480px;
    height: 480px;
    margin-right: 60px;
  }
  
  .renterspass-hero-img {
    width: 480px;
    height: 480px;
  }


.marketplace-img {
  width: 480px;
  height: 480px;
}

  .apppreview-hero-img {
    width: 480px;
    height: 480px;
  }

  
  .review-card {
    min-width: 90%;
    max-width: 90%;
    margin-top: 120px;
    background-color: #f9f9f9;
  }


.review-card h2 {
  font-size: 26px;
  width: 480px;
}


}

@media (max-width: 968px) {

  .section-wrapper {
    max-width: 968px;
    min-width: 100%;
    grid-template-columns: repeat(1, auto);
    margin-top: 0px;
  }

  .renterspass-hero {
  min-width: 100%;
  max-width: 968px;
  grid-template-columns: repeat(1, auto);
  margin-top: 60px;
  justify-items: center;
  text-align: center;
  }

  .renterspass-heading p {
    text-align: center;
  }

  .renterspass-heading h2 {
    text-align: center;
  }

  .property-feature-img {
    display: none;
  }
  
  .property-feature-img-mobile {
    background-image: url("../images/PropertyImage.png");
    width: 400px;
    height: 400px;
    background-size: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: block;
  }

  .renterspass-hero-img {
    width: 400px;
    height: 400px;
  }

  .apppreview-hero-img {
    width: 80%;
    height: 400px;
    margin-bottom: 100px;
    margin-top: 100px;
  }


.marketplace-img {
  width: 400px;
  height: 400px;
}

  .renterspass-heading {
    width: 60%;
    align-items: center;
    grid-template-columns: repeat(1, auto);
    margin-top: 60px;
    justify-items: center;
    text-align: center;
  }
  
  .property-feature-img {
    width: 100%;
    height: 400px;
  }

  .section-wrapper h2 {
    font-size: 38px;
  }

    
  .review-card {
    min-width: 80%;
    max-width: 80%;
    margin-top: 120px;
    background-color: #f9f9f9;
  }

  .review-card h2 {
    font-size: 20px;
  }
  .section-wrapper p {
    font-size: 16px;
  }

  .feature-list {
    grid-template-columns: repeat(1, auto);
    display: grid;
    align-items: center;
    justify-items: center;
    text-align: center;
    grid-gap: 0px;
  }
  
}

@media (max-width: 800px) {

  .section-wrapper {
    min-width: 100%;
    max-width: 100%;
    grid-template-columns: repeat(1, auto);
    margin-top: 0px;
  }

  .renterspass-hero {
    min-width: 100%;
    max-width: 100%;
  grid-template-columns: repeat(1, auto);
  margin-top: 60px;
  justify-items: center;
  }

  .renterspass-hero-img {
  background-image: url("../images/RentersPassImage.png");
  width: 100%;
  height: 400px;
  margin-top: 60px;
  }

  .renterspass-heading {
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 20px;
  width: 100%;

  }

  .renterspass-heading h2 {
    font-size: 2.4em;
   }



  .renterspass-heading p {
    font-size: 1em;
   }

/* 
  .property-feature-img {
    display: none;
  } */

  
  .property-feature-img-mobile {
    background-image: url("../images/PropertyImage.png");
    width: 100%;
    height: 400px;
    background-size: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: block;
  }

  .apppreview-hero-img {
    background-image: url("../images/aisonapppreview.png");
    width: 90%;
    height: 400;
    background-size: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
    border-radius: 34px;
    margin-top: 60px;
  }

  .review-card {
    width: 90%;
    background-color: #f9f9f9;
  }
  
  .review-card-wrapper {
    grid-template-columns: repeat(1, auto);
    display: grid;
    align-items: center;
    justify-items: center;
    padding: 40px;
  }
  
  .review-card h2 {
    font-size: 24px;
    line-height: 38px;
    width: 90%;
  }
  
  .ravicard h6 {
    font-size: 24px;
  }
  
  .ravicard p {
    margin-top: -60px;
    margin-bottom:80px;
  }
  

}


@media (max-width: 414px) {
  
  /* .renterspass-hero {
    min-width: 90%;
    max-width: 90%;
   } */

  /* .review-card h2 {
    font-size: 22px;
    width: 90%;
  }
     */
  .ravicard h6 {
    font-size: 20px;
  }
    
  .renterspass-heading h2 {
    font-size: 2em;
   }
  
}