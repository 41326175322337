.section-top {
  background-color: #fff;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-template-columns: auto;
  display:flex;
  align-items: center;
  justify-items: center;
  min-width: 100%;
  max-width: 100%;
  margin-top: 100px;
}

.cover-hero {
  border-radius: 0px;
  width: 1200px;
  height: 728px;
  grid-template-columns: repeat(2, auto);
  display: grid;
  align-items: center;
  justify-items: center;
  margin-top: 0px;
  margin-bottom: 0px;
  background-image: url("../images/homepage-room.jpg");
  background-size: 100% 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 24px;
  /* box-shadow:inset 0 0 3000px 3000px rgba(0, 0, 0, 0.2); */
  z-index: 2;
}

.cover-text {
   text-align: left;
   width: 90%;
}

.cover-text h1 {
  font-size: 68px;
  font-weight: 800;
  background: -webkit-linear-gradient(#fff, #fff);
  color: #fff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 20px;
}

.cover-text p {
  font-size: 20px;
  color: #fff;
  font-weight: 400;
}

.actions {
  grid-template-columns: repeat(2, auto);
  display: grid;
  align-items:center;
  justify-items: left;
  width: 340px;
}

.appoftheday {
  background-image: url("../images/appOfDay.webp");
  background-size: 100% 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 144px;
  height: 100px;
  margin-right: 40px;
}

.download-button {
  width: 184px;
  height: 52px;
  background-color: #18AFD1;
  border-radius: 26px;
  outline: none;
  cursor: pointer;
  box-shadow: 0px 0px 8px rgba(0,0,0, 0.15);
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1.0);
  /* text-align: center; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 60px; */
}

.download-button:hover {
  box-shadow: 0px 0px 16px rgba(0,0,0, 0.15);
  transform: translateY(-4px);
}

.download-button p {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  /* line-height: 37px;  */
}

.appdownload {
  height: 50px;
  margin: 8px;
}

.reviewhighlight {
  grid-template-columns: repeat(4, auto);
  display: grid;
  align-items: center;
  justify-items: center;
  grid-gap: 50px;
}

.reviewstars {
  grid-template-columns: repeat(2, auto);
  display: grid;
  align-items: center;
  justify-items: center;
  grid-gap: 0px;
}
.stars {
  width:140px; /*width of your image*/
  height:18px; /*height of your image*/
  background-image: url("../images/stars.png");
  background-size: 100% 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin:0; /* If you want no margin */
  padding:0; /*if your want to padding */
}

.bottom-hero {
  width: 100%;
  padding-top: 250px;
  padding-bottom: 100px;
  background-color: #F8F8F8;
  margin-top: -200px;
  margin-bottom: 0px;
  align-items: center;
  justify-items: center;
  grid-template-columns: repeat(1, auto);
  display: grid;
  z-index: 1;

}

.bottom-hero h5 {
  font-size: 32px;
  font-weight: 800;
}

.card-wrapper {
  width: 1200px;
  align-items: center;
  justify-items: center;
  grid-template-columns: repeat(2, auto);
  display: grid;
  margin-top: 100px;
}

.card-tenant {
  width: 580px;
  height: 480px;
  background-image: url("../images/tenant-card.jpg");
  background-size: 100% 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  box-shadow:inset 0 0 3000px 3000px rgba(0, 0, 0, 0.2);
  z-index: 2;
  border-radius: 34px;

}

.card-landlord {
  width: 580px;
  height: 480px;
  background-image: url("../images/landlord-card.jpg");
  background-size: 100% 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  box-shadow:inset 0 0 3000px 3000px rgba(0, 0, 0, 0.2);
  z-index: 2;
  border-radius: 34px;
}

.card-wrapper h1 {
  font-size: 38px;
  line-height: 1.8em;
  font-weight: 400;
  font-style: light;
  margin-left: 40px;
  color: #fff;
}

.card-wrapper p {
  font-size: 20px;
  line-height: 1.8em;
  font-style: normal;
  margin-left: 40px;
  margin-right: 80px;
  margin-top: 160px;
  color: #fff;
  font-weight: 200;

}

@media (max-width: 1440px) {

  .cover-hero {
    margin-top: 0px;
    margin-bottom: 0px;
    height: 640px;
    width: 90%;
  }

  .card-wrapper {
    width: 90%;
    align-items: center;
    justify-items: center;
    grid-template-columns: repeat(2, auto);
    display: grid;
    margin-top: 100px;
  }

}

@media (max-width: 1280px) {

  .cover-hero {
    width: 95%;
    height: 568px;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .cover-text {
    width: 95%;
 }

 .cover-text  h1 {
  font-size: 62px;
 }

  .card-wrapper {
    width: 100%;
  }
  
  .card-tenant {
    width: 90%;
    height: 480px;
  }
  
  .card-landlord {
    width: 90%;
    height: 480px;
  }

  .card-wrapper h1 {
    font-size: 32px;
  }
  
  .card-wrapper p {
    font-size: 20px;
    margin-top: 220px;
  }

  .download-button p {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    /* line-height: 37px;  */
  }
  
}


@media (max-width: 1024px) {

  .cover-hero {
    margin-top: 0px;
    margin-bottom: 0px;
   } 
   .reviewhighlight {
    width: 90%;
    grid-gap: 0px;
  }

  .bottom-hero h5 {
    width: 90%;
    font-size: 28px;
    text-align: center;
  }
  .reviewhighlight p {
     font-size: 18px;
  }

  .download-button p {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    /* line-height: 37px;  */
  }
  
}

@media (max-width: 960px) {

  .cover-hero {
    width: 95%;
    height: 720px;
  }

  .cover-text  h1 {
    font-size: 58px;
    font-weight: 800;
  }
  
  .cover-text p {
    font-size: 20px;
  }

  .bottom-hero {
    width: 100%;
    padding-top: 200px;
    padding-bottom: 100px;
    background-color: #f9f9f9;
    margin-top: -200px;
    margin-bottom: 00px;
    z-index: 1;
    align-items: center;
    justify-items: center;
    grid-template-columns: repeat(1, auto);
    display: grid;
  }

  .card-tenant {
    width: 80%;
    height: 480px;
    margin-right: 0px;
  }
  
  .card-landlord {
    width: 80%;
    height: 480px;
  }
  
  .card-wrapper h1 {
    font-size: 2em;
  }
  
  .card-wrapper p {
    font-size: 18px;
    margin-top: 0px;
  }

  
/* .download-button {
  width: 144px;
  height: 42px;
  background-color: #18AFD1;
  border-radius: 26px;
  outline: none;
  cursor: pointer;
  box-shadow: 0px 0px 8px rgba(0,0,0, 0.15);
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1.0);
  display: flex;
  justify-content: center;
  align-items: center;
}
*/
.download-button p {
  font-size: 18px;
}

}

@media (max-width: 800px) {

  .section-top {
    margin-top: 0px;
  }

  .cover-hero {
    margin-top: 0px;
   } 

   .reviewhighlight {
    width: 90%;
    grid-gap: 0px;
  }

 .reviewstars p {
  display: none;
 }
 .stars {
  width: 100px; /*width of your image*/
  height:20px; /*height of your image*/
  margin-left: 0px;
}
  .card-wrapper {
    margin-top:100px;
  }

  .card-wrapper p {
    font-size: 18px;
  }

  .download-button p {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
  }

  .download-button {
    display: flex;
  }
  
}


@media (max-width: 728px) {

  .section-top {
    margin: 0px;
    margin-bottom: -250px;
  }
  
  .cover-hero {
    width: 95%;
    height: 560px;
  }

  .cover-text  h1 {
    font-size: 3.6em;
    font-weight: 800;
  }

  .cover-text p {
    font-size: 1.2em;
    display: none;
  }

  .download-button {
    display: none;
  }
  .appdownload {
    height: 36px;
    margin: 8px;
  }

  .stars {
    width: 100px; /*width of your image*/
    height:50px; /*height of your image*/
  }

  .reviewhighlight {
    grid-template-columns: repeat(1, auto);
    display: grid;
    align-items: center;
    justify-items: center;
    grid-gap: 0px;
  }


  .bottom-hero h5 {
    font-size: 26px;
    font-weight: 800;
    text-align: center;
  }

  .card-wrapper h1 {
    font-size: 28px;
  }

  .card-wrapper p {
    font-size: 20px;
    margin-top: 20px;
  }

  .bottom-hero {
    width: 100%;
    padding-top: 200px;
    padding-bottom: 100px;
    grid-template-columns: repeat(1, auto);
    display: grid;
    margin-bottom: 200px;
  }

  .card-wrapper {
    grid-template-columns: repeat(1, auto);
    display: grid;
    align-items: center;
    justify-content: center;
    margin-top:100px;
  }

  .card-tenant {
    width: 90%;
    height: 380px;
    margin-right: 0px;
    margin-bottom: 40px;
  }
  
  .card-landlord {
    width: 90%;
    height: 380px;
  }


}

@media (max-width: 414px) {

  .section-top {
    margin: 0px;
    margin-bottom: -300px;
    min-width: 100%;
    max-width: 414px;
  }
  
  .download-button {
    display: none;
  }
  
  .cover-hero {
    min-width: 90%;
    max-width: 90%;
    height: 440px;
  }

  .appdownload {
    height: 36px;
    margin: 8px;
  }

  .reviewhighlight {
    width: 90%;
    grid-gap: 0px;
  }

  .bottom-hero h5 {
    font-size: 20px;
    width: 90%;
  }

  .reviewhighlight p {
    font-size: 18px;
 }

 .cover-text {
  width: 90%;
 }

  .cover-text h1 {
    font-size: 3.2em;
  }

  .cover-text p {
    font-size: 0.9em;
    margin-top: 10px;
  }

  .card-wrapper p {
    font-size: 18px;
    margin-top: 20px;
  }

  

  .bottom-hero {
    min-width: 100%;
    max-width: 100%;
    padding-top: 250px;
    padding-bottom: 100px;
    grid-template-columns: repeat(1, auto);
    display: grid;
  }

}
