.coming-Soon {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  max-width: 100%;
  margin-top: 100px;
  margin-bottom: 100px;
}

.comingSoon-Feature {
  width: 1200px;
  grid-template-columns: repeat(0, auto);
  display: grid;
  align-items: center;
  justify-items: center;
  text-align: center;
  border-radius: 34px;
  background:#f8f8f8;
  display: flex;
  flex-direction: column;
  margin-bottom: 0px;
}

.coming-soon-text h1 {
  font-size: 48px;
  line-height: 2em;
  font-weight: 800;
  font-style: normal;
  margin-top: 40px;
}

.comingSoon-Feature p {
  width: 800px;
  font-size: 20px;

}

.comingSoon-img {
  background-image: url("../images/aison-market.png");
  width: 600px;
  height: 600px;
  background-size: 100% 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 40px;
}

.get-early-access {
  width: 100%;
  height: 50vh;
  background-color:#f9f9f9;
  color: #ffffff;
  text-align: center;
  grid-template-columns: auto;
  display: grid;
  align-items: center;
  justify-items: center;
  padding-bottom: 0px;
  margin-top: 80px;
}



.downloadButton
 {
  width: 160px;
  height: 52px;
  background-color: #18AFD1;
  border-radius: 26px;
  outline: none;
  cursor: pointer;
  box-shadow: 0px 0px 8px rgba(0,0,0, 0.15);
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1.0);
  justify-items: center;
  text-align: center;
}

.downloadButton:hover {
  box-shadow: 0px 0px 16px rgba(0,0,0, 0.15);
  transform: translateY(-4px);
  /* background-color: #0828BA; */
}

.downloadButton p {
   color: #fff;
   font-size: 16px;
   font-family: "neue-haas-grotesk-display", sans-serif;
   font-weight: 600;
   margin-top: 10px;
}


@media (max-width: 1200px) {

  .coming-Soon {
  min-width: 100%;
  max-width: 100%;
  }

  .comingSoon-Feature {
  width: 90%;
  }

  .comingSoon-img {
    width: 90%;
  }

  .comingSoon-Feature p {
    width: 100%;
    font-size: 18px;
  }
  .coming-soon-text h1 {
    font-size: 38px;
  }
  

}

@media (max-width: 800px) {


    .coming-Soon {
    min-width: 100%;
    max-width: 100%;

    }

    .comingSoon-Feature {
    width: 90%;
    }

    .comingSoon-img {
      width: 90%;
      height: 400px;
    }

    .coming-soon-text {
      width: 90%;
    }

    .comingSoon-Feature p {
      width: 100%;
      font-size: 18px;
    }
    
    .coming-soon-text h1 {
      font-size: 38px;
    }

    .get-early-access h2 { 
      width: 90%;
    }
}

@media (max-width: 568px) {

}

@media (max-width: 425px) {

}

@media (max-width: 375px) {

}
