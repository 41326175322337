.Legal-Content-Wrapper {
    max-width: 800px;
    text-align: left;
    margin: auto;
    margin-top: 124px;
    margin-bottom: 124px;
}

@media (max-width: 800px) {

  .Legal-Content-Wrapper {
    max-width: 95%;
    text-align: left;
    margin: auto;
    margin-top: 124px;
    margin-bottom: 124px;

  }

}
